import {Divider, Typography} from "@mui/material"

const HelperText = () => {
  return(
    <>
      <Divider variant="middle">
        <Typography variant="subtitle">
          {"Find your next job using AI"}
        </Typography>
      </Divider>
    </>
  )
}

export default HelperText
